<template>
  <div>
    <v-card-text>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        clearable
        solo
        :items="allStates"
        v-model="state_filter"
        item-text="state_name"
        item-value="id"
        label="State*"
        required
        :return-object="true"
        :rules="[rules.required('State')]"
        @change="loadDistricts(state_filter.id, 'Active')"
        :search="search"
      ></v-autocomplete>

      <v-data-table
        v-model="selectedDistricts"
        :headers="headers"
        :items="allDistricts"
        fixed-header
        height="400px"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        dense
        :search="search"
        item-key="id"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title>Districts</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              color="error"
              outlined
              :loading="isLoading"
              v-if="selectedDistricts.length > 0"
              @click="deleteSelectedHandler"
              >Delete Selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              Total
              <v-avatar right class="green darken-4">
                {{ allDistricts.length }}
              </v-avatar>
            </v-chip>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              style="max-width: 300px"
            ></v-text-field>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" text color="indigo">
                  {{ selectedStatus }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="status in ['Active', 'Deleted']"
                  :key="status"
                  @click="changeStatusFilter(status)"
                  dense
                >
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn dark class="primary" @click="addNewHandler">
              <v-icon left dark> mdi-plus </v-icon>
              ADD NEW
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add New District</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            :items="allStates"
                            v-model="state"
                            item-text="state_name"
                            item-value="state_name"
                            label="State*"
                            required
                            :rules="[rules.required('State')]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="district"
                            label="District*"
                            required
                            :rules="[rules.required('District')]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="postData">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
            small
            @click="
              changeStatus(
                'district_code',
                item.district_code,
                item.status,
                state_filter
              )
            "
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.state_name="{ item }">
          {{ item.state_id ? state_filter.state_name : "" }}
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import rules from "../helpers/validation";
import axios from "axios";
import { baseURL } from "../constant/constant";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    selectedStatus: "Active",
    moment: moment,
    tableLoading: false,
    dialog: false,
    valid: true,
    state: "",
    district: "",
    state_filter: "",
    rules: rules,
    search: "",
    selectedDistricts: [],
    isLoading: false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      {
        text: "District Name",
        value: "district_name",
      },
      {
        text: "State Name",
        value: "state_name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "allStates",
      "alertType",
      "alertMessage",
      "allDistricts",
      "updateEntityStatus",
    ]),
    ...mapState(["response"]),
  },
  mounted() {
    this.$store.commit("set_districts", []);
    this.$store.dispatch("fetchStates");
  },
  methods: {
    ...mapActions(["fetchStates", "fetchDistricts", "addNewDistrict"]),
    async changeStatusFilter(status) {
      this.selectedStatus = status;
      await this.loadDistricts(this.state_filter.id, this.selectedStatus);
    },
    async postData() {
      const payload = {
        locations: [
          {
            name: this.district,
            vote_limit: 5000,
          },
        ],
        state: this.state,
      };

      await this.addNewDistrict(payload);

      if (this.state_filter) {
        await this.loadDistricts(this.state_filter.id, this.selectedStatus);
      }
      this.closeDialog();
    },
    addNewHandler() {
      if (this.state_filter) {
        this.state = this.state_filter.state_name;
      }
      this.dialog = true;
    },
    deleteSelectedHandler() {
      this.isLoading = true;
      axios
        .delete(`${baseURL}admin/districts`, {
          data: {
            ids: this.selectedDistricts.map((v) => v.id),
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: `Deleted Successfully!`,
          });
          this.selectedDistricts = [];
          if (this.state_filter) {
            this.loadDistricts(this.state_filter.id, this.selectedStatus);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
        });
    },
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
